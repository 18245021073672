.calendar-timeline-background {
  margin-top: 1%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.12), 2px 2px 3px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.CalendarLoading .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  font-size: 8em;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.CalendarLoading {
  width: 100%;
  margin-top: 5%;
  text-align: center;
}

.react-calendar-timeline .rct-header .rct-label-group {
  font-weight: bold;
  background-color: #003049;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-header {
  background-color: #003049;
}

.react-calendar-timeline .rct-horizontal-lines {
  color: white;
}

.react-calendar-timeline .rct-items .rct-item.pending {
  background-color: sandybrown;
  font-weight: bold;
}

.react-calendar-timeline .rct-items .rct-item.approved {
  background-color: #20bf55;
  font-weight: bold;
}

.calendar-timeline-background {
  background-color: white;
  max-height: calc(115vh - 25em);
  overflow-y: scroll;
}

.rct-sidebar-header {
  position: sticky;
}

.rct-header {
  position: sticky;
}
